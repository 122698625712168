<template>
	<div class="center">
		<div class="box login">
			<h1 class="title">Slice of Keesh</h1>
			<h2 class="subtitle">Admin Panel</h2>
			<button class="button is-primary" @click="login">Log In</button>
		</div>
	</div>
</template>

<script>
import { auth, provider } from '../firebase';
import router from '../router/index';

export default {
	methods: {
		login: async function() {
			try {
				await auth.signInWithPopup(provider);
				router.push('/');
			} catch (error) {
				console.error(error.message);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.login {
	width: 300px;
}
</style>
